import React, { useEffect } from "react";

const PixelBackground = () => {
  useEffect(() => {
    const background = document.querySelector(".pixel-background");
    if (background.children.length === 0) {
      for (let i = 0; i < 300; i++) {
        const pixel = document.createElement("div");
        pixel.classList.add("pixel");
        pixel.style.left = `${Math.random() * 100}vw`;
        pixel.style.top = `${Math.random() * 100}vh`;
        background.appendChild(pixel);
      }
    }
  }, []);

  return <div className="pixel-background"></div>;
};

export default PixelBackground;
