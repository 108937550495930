import React, { useEffect, useState } from "react";

const Tokens = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true); // To manage the loading state
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    // Fetch tokens from the Flask backend
    const fetchTokens = async () => {
      try {
        const response = await fetch("https://109.236.91.154/read_all?collection=tokens");
        if (!response.ok) {
          throw new Error("Failed to fetch tokens");
        }
        const data = await response.json();
        console.log(data);
        setTokens(data); // Update the tokens state
      } catch (err) {
        setError(err.message); // Update the error state
      } finally {
        setLoading(false); // Stop the loading state
      }
    };

    fetchTokens();
  }, []);

  if (loading) {
    return <p>Loading tokens...</p>; // Show a loading message
  }

  if (error) {
    return <p>Error: {error}</p>; // Show error message if the fetch fails
  }

  return (
    <div className="tokens-page">
      <h2>Meme Tokens Deployed by ROBOTO</h2>
      <div className="tokens-grid">
        {tokens.length > 0 ? (
          tokens.map((token) => (
            <div key={token.id} className="token-card">
              <h3>{token.TokenDynAddress}</h3>
              <p>Address: {token.TokenDynMint}</p>
              <p>Claimers: ${token.TotalHolders}</p>
              <p>Metadata: ${token.TokenDynURI}</p>
            </div>
          ))
        ) : (
          <p>No tokens found.</p>
        )}
      </div>
    </div>
  );
};

export default Tokens;
