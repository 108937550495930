import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Tokens from "./pages/Tokens";
import Profile from "./pages/Profile";
import Header from "./components/Header";
import PixelBackground from "./components/PixelBackground";

const App = () => {
  return (
    <div>
      <PixelBackground />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tokens" element={<Tokens />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </div>
  );
};

export default App;
