import React from 'react';

const Home = () => {
  return (
    <div>
      <section className="hero">
        <h1>This is TRINITY</h1>
        <p>The First AI Agent for Decentralized Token Ecosystems</p>
        <button className="retro-button">Explore the Future</button>
      </section>

      <section className="features">
        <h2>Features</h2>
        <div className="features-grid">
          <div className="feature-card">AI-Generated Memes</div>
          <div className="feature-card">Blockchain Integration</div>
          <div className="feature-card">Secure Rewards</div>
        </div>
      </section>

      <section className="tokens-container">
        <h2>System Workflow</h2>
        <div className="tokens-grid">
          <div className="token-card">
            <h3>Self-Deployment</h3>
            <p>The system deploys itself on the blockchain, initializing its Main Token</p>
            <p></p>
          </div>
          <div className="token-card">
            <h3>Dynamic Tokens</h3>
            <p>Triarch autonomously generates and deploys Dynamic Tokens</p>
            <p></p>
          </div>
          <div className="token-card">
            <h3>Revenue Distribution</h3>
            <p>Smart contracts autonomously calculate and distribute rewards to Main Token holders</p>
            <p></p>
          </div>
        </div>
      </section>

      <div className="footer-elements">
        <div className="footer-text">
          ca: 0xFC12b25Cc16Cf446E9794E5B868e11332f7e31A6
        </div>
        <div className="social-links">
          <a href="https://x.com/TrinityAIagent" target="_blank" rel="noopener noreferrer">
            <img src="/twitter.png" alt="Twitter" />
          </a>
          <a href="https://t.me/" target="_blank" rel="noopener noreferrer">
            <img src="/telegram.png" alt="Telegram" />
          </a>
          <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <img src="/medium.png" alt="GitHub" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
